import React, { useCallback, useEffect, useState } from 'react';
import {
  AiFillFacebook,
  AiFillLinkedin,
  AiFillYoutube,
  AiOutlineInstagram,
} from 'react-icons/ai';
import { IoLogoWhatsapp } from 'react-icons/io';
import { VscSend } from 'react-icons/vsc';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLocale } from '../../contexts';
import { fetchFooter, FooterSectionTexts } from '../../services/strapiService';

const Rodape: React.FC = () => {
  const navigate = useNavigate();
  const [footerTexts, setFooterTexts] = useState<FooterSectionTexts>({} as FooterSectionTexts);
  const { locale } = useLocale();

  const getFooterText = useCallback(async () => {
    try {
      const { data } = await fetchFooter(locale);
      setFooterTexts(data?.data);
    } catch (error) {
      console.error('Erro ao carregar informações do footer', error);
    }
  }, [locale]);

  useEffect(() => {
    getFooterText();
  }, [getFooterText]);

  const handleFooterLinkClick = (link: { href: string }) => {
    if (!link || !link.href) return;

    // Extraindo a última parte do href como slug (caso o Strapi não retorne `type`)
    const industrySlug = link.href.split('/').pop() || '';

    if (industrySlug) {
      navigate(`/industrias/${industrySlug}`);
    }
  };

  return (
    <FooterContainer>
      <FooterWrapper>

        <Column>

          <h3>Informações da empresa</h3>
          <p style={
            {
              color: 'white',
              fontSize: '16px',
              fontWeight: '400'
            }
          }>Endereço: Avenida Paulista, 1636 Cj 4 - PAV. 15, sala, 1504 - Cerqueira César, São Paulo - SP, 01310-200 <br /> <br />




          </p>

        </Column>

        {footerTexts?.list?.map((item) => (
          <Column key={item?.title}>
            <h3>{item?.title}</h3>
            {item?.footerLinks?.map((link) => (
              <FooterLink
                key={link?.label}
                onClick={() => handleFooterLinkClick(link)}
              >
                {link?.label}
              </FooterLink>

            ))}

          </Column>
        ))}

        <Column>
          <h3>{footerTexts?.title}</h3>
          <p>{footerTexts?.subtitle}</p>
          <NewsletterForm>
            <input type='email' placeholder='E-mail' />
            <button>
              <VscSend />
            </button>
          </NewsletterForm>
          <FooterNote>{footerTexts?.policyText}</FooterNote>
        </Column>
      </FooterWrapper>
      <FooterBottom>
        <FooterCopyright>{footerTexts?.rightsReserved}</FooterCopyright>
        <SocialIcons>
          <AiFillFacebook />
          <AiOutlineInstagram />
          <IoLogoWhatsapp />
          <AiFillLinkedin />
          <AiFillYoutube />
        </SocialIcons>
      </FooterBottom>
    </FooterContainer >
  );
};

export default Rodape;

// Styled Components
const FooterContainer = styled.footer`
  background-color: #0024be;
  padding: 40px 20px;
  color: white;
  width: 100%;
`;

const FooterWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Column = styled.div`
  flex: 1;
  margin: 20px;

  h3 {
    font-size: 15px;
    font-weight: 700;
    margin-bottom: 15px;
    color: var(--grey);
  }

  p {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 15px;
  }

  @media (max-width: 768px) {
    margin: 0;
    padding: 0;
    margin-bottom: 20px;
  }
`;

const FooterLink = styled.div`
  display: block;
  margin-bottom: 10px;
  color: #ffffff95;
  line-height: 21px;
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  transition: color 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    color: var(--green);
  }
`;

const NewsletterForm = styled.form`
  display: flex;
  align-items: center;
  margin-top: 15px;

  input {
    flex: 0.9;
    padding: 17px 15px;
    height: 59px;
    border: none;
    outline: none;
    font-size: 14px;
    background-color: white;
    color: white;
  }

  button {
    height: 59px;
    display: flex;
    flex: 0.1;
    align-items: center;
    justify-content: center;
    margin-left: 16px;
    padding: 10px 15px;
    border: none;
    background-color: var(--green);
    color: white;
    cursor: pointer;

    &:hover {
      background-color: var(--soft-blue);
    }
  }
`;

const FooterNote = styled.p`
  font-size: 12px;
  font-weight: 400;
  color: white;
  margin-top: 16px;
`;

const FooterBottom = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  border-top: 1px solid var(--grey);
  padding-top: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterCopyright = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: var(--white-transparent);
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 15px;

  svg {
    font-size: 24px;
    cursor: pointer;
    color: var(--grey);

    &:hover {
      color: var(--green);
    }
  }
`;
