import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { IoIosGitCommit } from 'react-icons/io'
import { PiTranslate } from 'react-icons/pi'
import { useLocale } from '../../contexts'
import {
  fetchTranslatorBannerTexts,
  imageUrl,
  MainTranslatorBanner,
} from '../../services/strapiService'

const BannerTranslator: React.FC = () => {
  const [translatorBannerTexts, setTranslatorBannerText] =
    useState<MainTranslatorBanner>({} as MainTranslatorBanner)

  const { locale } = useLocale()

  const handleTranslatorButtonClick = () => {
    const element = document.getElementById('form-register-translator')
    element?.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const getTranslatorBannerText = async () => {
    try {
      const { data } = await fetchTranslatorBannerTexts(locale)

      setTranslatorBannerText(data?.data)
    } catch (error) {
      console.error('Erro ao buscar dados do banner do tradutor:', error)
    }
  }

  useEffect(() => {
    getTranslatorBannerText()
  }, [locale])

  return (
    <BannerWrapper>
      <BannerContent>
        <CultureText>
          <IoIosGitCommit color='#0124BE' />
          {translatorBannerTexts?.badge}
        </CultureText>
        <TitleContent>
          <HeroTitle>{translatorBannerTexts?.title}</HeroTitle>
        </TitleContent>
        <Subtitle>{translatorBannerTexts?.subtitle}</Subtitle>
        <ButtonGroup>
          <Button onClick={handleTranslatorButtonClick}>
            {translatorBannerTexts?.buttonLabelBeTranslator} <PiTranslate />
          </Button>
        </ButtonGroup>
      </BannerContent>
      <BannerImage
        src={imageUrl(translatorBannerTexts?.image?.url)}
        alt='Banner Image'
      />
    </BannerWrapper>
  )
}

export default BannerTranslator

const TitleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media (max-width: 768px) {
    align-items: center;
    gap: 5px;
    margin-bottom: 1rem;
  }
`

const BannerWrapper = styled.section`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 120px 90px;
  background-color: white;
  position: relative;
  height: 80vh;

  @media (max-width: 768px) {
    flex-direction: column;
    height: auto;
    padding: 2rem;
  }
`

const BannerContent = styled.div`
  max-width: 600px;
  z-index: 1;

  @media (max-width: 768px) {
    max-width: 100%;
    text-align: center;
    align-items: center;
  }
`

const CultureText = styled.p`
  font-size: 21px;
  font-weight: 400;
  font-family: 'Inter', sans-serif;
  color: var(--hard-blue);
  margin-bottom: 24px;
  display: flex;
  gap: 10px;

  svg {
    font-size: 21px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`

const HeroTitle = styled.h1`
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 48px;
  text-align: left;
  color: var(--hard-blue);
  margin-bottom: 24px;

  @media (max-width: 768px) {
    font-size: 44px;
  }
`

const Subtitle = styled.p`
  font-family: 'Inter', sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #4d4d4d;
  margin-bottom: 44px;
`

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`

const Button = styled.button<{ primary?: boolean }>`
  display: flex;
  align-items: center;
  gap: 20px;
  background-color: var(--hard-blue);
  border: 0;
  padding: 15px 28px;
  cursor: pointer;
  border-radius: 2px;
  transition: background-color 0.3s ease;
  justify-content: center;

  font-family: 'Inter', sans-serif;
  font-size: 21.2px;
  font-weight: 400;
  line-height: 31.79px;
  text-align: left;
  color: white;

  &:hover {
    background-color: var(--soft-blue);
    backdrop-filter: blur(2px);
  }
`

const BannerImage = styled.img`
  position: absolute;

  right: 4vw;
  bottom: 0;
  height: 80vh;
  z-index: 0;
  object-fit: fill;

  @media (max-width: 1024px) {
    height: 20vh;
    height: 50vh;
    right: 0vw;
  }

  @media (max-width: 768px) {
    display: none;
  }
`
