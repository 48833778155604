import styled from 'styled-components';

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  overflow: scroll;
`;

export const ModalContent = styled.div`
  background: #f4f4f4;
  padding: 10px;
  border-radius: 0px;
  width: 500px;
  max-width: 90%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  position: relative;

  h2 {
    font-size: 19px !important;
    font-weight: 400;
    line-height: 22.8px;
    text-align: left;
    margin-bottom: 20px;
    color: #000 !important;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: transparent;
  border: none;
  font-size: 20px;
  cursor: pointer;
`;

export const StepProgress = styled.div`
  display: flex;
  margin-top: 20px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 40px;
  width: 100%;
`;

export const StepItem = styled.div<{ active: boolean }>`
  width: 100%;
  height: 6px;
  border-radius: 6px;
  background: ${(props) => (props.active ? '#0056ff' : '#ddd')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
`;

export const StepCircleItem = styled.div<{ active: boolean }>`
  width: 20px;
  height: 20px;
  padding: 15px;
  border-radius: 100%;
  background: ${(props) => (props.active ? '#0056ff' : '#ddd')};
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 20px;

  h2 {
    margin-bottom: 20px;
  }

  .success-text {
    text-align: center;
    font-size: 33.94px;
    font-weight: 400;
    line-height: 38.69px;
  }

  select,
  textarea {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
  }

  textarea {
    height: 80px;
  }
`;

export const InputWrapper = styled.div`
  flex: 1;
  display: flex;
  margin-bottom: 20px;
  flex-direction: column;

  .label-text {
    font-size: 15px;
    line-height: 1.2;
    color: #000;
    font-weight: 400;
    margin-top: -25px;
    padding-bottom: 6px;
    text-align: left !important;
    left: 0px;
    transition: all 0.2s ease;
    z-index: 2;
  }

  .label-file {
    font-size: 15px !important;
    line-height: 1.2;
    text-align: left;
    color: #000;
    font-weight: 400;
    padding-bottom: 6px;
    left: 0px;
    transition: all 0.2s ease;
    z-index: 2;
  }
`;

export const InputWrapperText = styled.div`
  display: flex;
  font-family: 'Roboto', sans-serif;
  align-items: start;
  gap: 10px;
  border: 1px solid #a9a9a9;
  border-radius: 0px;
  background-color: #ffffff;
  padding: 0 20px;
  height: 90px;
  input {
    border: none;
    outline: none;
    height: 100%;
    font-size: 16px;
    flex: 1;
  }

  textarea {
    border: none;
    outline: none;
    height: 100%;
    font-size: 16px;
    flex: 1;
    resize: none;

    ::placeholder {
      color: #a9a9a9;
      font-size: 16px;
      font-weight: 400;
      line-height: 19.36px;
      text-align: left;
    }
  }

  svg {
    font-size: 20px;
    color: #a9a9a9;
    margin-top: 10px;
  }
`;

export const CustomSelectWrapper = styled.div`
  position: relative;
  cursor: pointer;

  .custom-select {
    display: flex;
    align-items: center;
    justify-content: start;
    background: #fff;
    gap: 10px;
    border: 1px solid #a9a9a9;
    border-radius: 0px;
    background-color: #ffffff;
    padding: 0 20px;
    height: 60px;

    svg {
      font-size: 20px;
      color: #a9a9a9;
    }
  }

  .custom-options {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
    margin-top: 5px;
    z-index: 100;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    svg {
      font-size: 20px;
      color: #a9a9a9;
    }

    .custom-option {
      padding: 10px;
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: 10px;

      &:hover {
        background-color: #f0f0f0;
      }
    }
  }
`;

export const LanguageMultiSelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  .multi-select {
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background: #fff;
  }
`;

export const NextButton = styled.button`
  background: #0056ff;
  color: white;
  border: none;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  height: 60px;
  cursor: pointer;
  font-size: 16px;
  transition: all 0.3s ease;

  &:hover {
    background: #0024be;
  }
`;

export const SuccessIcon = styled.div`
  display: flex;
  font-size: 50px;
  color: #4caf50;
  margin-bottom: 20px;
  justify-content: center;
`;

export const SingleValueLabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #4d4d4d;

  img {
    border-radius: 100%;
  }
`;

export const OptionContainer = styled.div<{ isFocused: boolean }>`
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  color: ${({ isFocused }) => (isFocused ? '#FFF' : '#4d4d4d')};
  cursor: pointer;

  background-color: ${({ isFocused }) =>
    isFocused ? 'var(--hard-blue)' : '#FFF'};

  img {
    border-radius: 100%;
  }
`;

export const SelectLanguagesWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: -20px;
`;

export const InputWrapperFile = styled.div`
  margin-top: 30px;
`;

export const InputWrapperTextDate = styled.div`
  margin-top: -20px;
  text-align: left;
  font-size: 15px !important;
  line-height: 1.2;
  text-align: left;
  color: #000;
  font-weight: 400;
  padding-bottom: 6px;
  left: 0px;
  transition: all 0.2s ease;
`;

export const InputLabelFile = styled.h1`
  margin-top: 10px;
  text-align: left;
  font-size: 15px !important;
  line-height: 1.2;
  text-align: left;
  color: #000;
  font-weight: 400;
  padding-bottom: 6px;
  left: 0px;
  transition: all 0.2s ease;
`;

export const InputLabelTextDate = styled.h1`
  margin-top: 10px;
  text-align: left;
  font-size: 15px !important;
  line-height: 1.2;
  text-align: left;
  color: #000;
  font-weight: 400;
  padding-bottom: 6px;
  left: 0px;
  transition: all 0.2s ease;
`;

export const InputDate = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #a9a9a9;
  border-radius: 0px;
  background-color: #ffffff;
  padding: 0 20px;
  height: 60px;
  margin-bottom: 10px;

  input {
    border: none;
    outline: none;
    height: 100%;
    font-size: 16px;
    flex: 1;
  }

  svg {
    font-size: 20px;
    color: #a9a9a9;
  }
`;

export const FileInputWrapper = styled.button`
  display: flex;
  align-items: center;
  padding: 10px;
  border: none;
  height: 60px !important;
  padding: 10px 25px !important;
  gap: 8px;
  background-color: #e4e4e4;
  cursor: pointer;
`;

export const FileInput = styled.input`
  display: none;
`;

export const PlaceholderFileInput = styled.div`
  font-weight: 400;
  text-align: left;
  color: #4d4d4d;
  font-size: 14px;
  margin-left: 10px;
`;

export const SuccessText = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  h2 {
    margin: 0;
  }

  .success-container {
    display: flex;
    gap: 4px;
    flex: 1;
    align-items: center;
    justify-content: center;
  }
`;
