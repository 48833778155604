import { useEffect } from 'react';

function HubSpotForm() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = "https://js.hsforms.net/forms/embed/48872911.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    return () => {
      // Limpeza ao desmontar o componente
      document.body.removeChild(script);
    };
  }, []);

  return <div className="hs-form-frame" data-region="na1" data-form-id="5b16a4d8-cd4a-4e79-92cd-54404577bab1" data-portal-id="48872911"></div>;
}

export default HubSpotForm;
