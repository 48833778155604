
import React, { useEffect, useRef, useState } from 'react'
import {
  OptionProps,
  SingleValue,
  SingleValueProps,
} from 'react-select'

import { zodResolver } from '@hookform/resolvers/zod'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import * as S from './styles'

import { PiTranslateDuotone } from 'react-icons/pi'
import { useLocale } from '../../contexts'
import {
  fetchRegisterTranslatorModalForm,
  postRequestQuote,
  RegisterTranslatorModalForm,
} from '../../services/strapiService'
import HubSpotForm from '../HubSpotForm'

type Values = {
  value: string
  label: string
  flag?: any
}

type SelectValuesType = SingleValue<Values>

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  serviceType?: SelectValuesType
}

const schema = z.object({
  nome: z.string().min(1, 'Campo obrigatório'),
  email: z.string().email('E-mail inválido'),
  telefone: z
    .string()
    .min(1, { message: 'Número obrigatório' })
    .min(14, 'Número inválido'),
})

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, serviceType }) => {
  const [step, setStep] = useState(1)
  const [modalFormTexts, setModalFormTexts] =
    useState<RegisterTranslatorModalForm>({} as RegisterTranslatorModalForm)
  const [formData, setFormData] = useState({
    projetoTipo: serviceType,
    origem: {} as SelectValuesType,
    destino: {} as SelectValuesType,
    detalhesProjeto: '',
    dataEntrega: '',
    arquivo: null as File | null,
  })
  const refFileInput = useRef<HTMLInputElement>(null)

  const { locale } = useLocale()

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<z.TypeOf<typeof schema>>({
    resolver: zodResolver(schema),
  })

  const fetchModalFormTexts = async () => {
    try {
      const { data } = await fetchRegisterTranslatorModalForm(locale)
      setModalFormTexts(data?.data)
    } catch (error) { }
  }

  useEffect(() => {
    fetchModalFormTexts()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  useEffect(() => {
    setFormData((prevData) => ({ ...prevData, projetoTipo: serviceType }))
  }, [serviceType])

  const handleNextStep = () => {
    if (step < 2) {
      setStep(step + 1)
    } else {
      handleSubmit(handleRequestQuote)()
    }
  }

  const handleRequestQuote = async (data: z.TypeOf<typeof schema>) => {
    if (!formData.arquivo) return alert('Campo de Arquivo é obrigatório!')

    const projectType = formData.projetoTipo?.value
    if (!projectType) return alert('Campo de Tipo de Projeto obrigatório!')

    const origin = formData.origem?.value
    if (!origin) return alert('Campo de Idiomas obrigatório!')

    const destiny = formData.destino?.value
    if (!destiny) return alert('Campo de Idiomas obrigatório!')

    try {
      await postRequestQuote({
        ...data,
        ...formData,
        projetoTipo: projectType,
        origem: origin,
        destino: destiny,
      })
      setStep(step + 1)
    } catch (error) {
      alert('Ocorreu um erro ao cadastrar suas informações.')
    }
  }

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null
    setFormData({ ...formData, arquivo: file })
  }

  const SingleValue = (
    props: SingleValueProps<{
      value: string
      label: string
      flag?: any
    }>
  ) => (
    <S.SingleValueLabelContainer>
      {props.data?.flag ? (
        <img
          src={props.data?.flag}
          alt={`Bandeira ${props?.data?.flag}`}
          width={20}
          height={20}
        />
      ) : (
        <PiTranslateDuotone width={25} height={25} color='#A9A9A9' />
      )}
      {props?.data?.label}
    </S.SingleValueLabelContainer>
  )

  const Option = (
    props: OptionProps<{
      value: string
      label: string
      flag?: any
    }>
  ) => (
    <S.OptionContainer
      ref={props.innerRef}
      {...props.innerProps}
      isFocused={props?.isFocused}
    >
      {props.data?.flag ? (
        <img
          src={props.data?.flag}
          alt={`Bandeira ${props?.data?.flag}`}
          width={20}
          height={20}
        />
      ) : (
        <PiTranslateDuotone width={25} height={25} color='#A9A9A9' />
      )}
      {props?.data?.label}
    </S.OptionContainer>
  )

  if (!isOpen) return null

  return (
    <S.ModalOverlay
      className='modal-container'
      onClick={(e) => {
        //@ts-ignore
        const classNameModal = e.nativeEvent.target.className
        classNameModal?.split(' ').includes('modal-container') && onClose()
      }}
    >
      <S.ModalContent>

        <HubSpotForm />
      </S.ModalContent>
    </S.ModalOverlay>
  )
}

export default Modal
