// src/pages/Home.tsx
import React from 'react'
import Banner from '../../components/Banner'
import FAQData from '../../components/FAQData'
import Industries from '../../components/IndustriesBox'
import Solutions from '../../components/SolutionsBox'
import Why from '../../components/Why'
import WorkProcess from '../../components/WorkProcess'
import * as S from './styles'

const Home: React.FC = () => {
  return (
    <S.HomeWrapper>
      <Banner />
      <Why />
      <Solutions />
      <Industries />
      <WorkProcess />
      {/* <Clients /> */}
      <FAQData />
    </S.HomeWrapper>
  )
}

export default Home
