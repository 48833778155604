import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { AiOutlinePlus } from 'react-icons/ai'
import { GoArrowUpRight } from 'react-icons/go'
import { PiTranslate } from 'react-icons/pi'

import { useLocale } from '../../contexts'
import {
  DefaultFields,
  fetchSolutions,
  fetchSolutionsText,
  imageUrl,
  Solution,
} from '../../services/strapiService'
import Modal from '../Modal'

const SolutionsBox = ({
  lightBackground = false,
  isGrid = false,
}: {
  lightBackground?: boolean,
  isGrid?: boolean,
}) => {
  const [solutions, setSolutions] = useState<Solution[]>([])
  const [solutionsText, setSolutionsText] = useState<
    Pick<DefaultFields, 'title' | 'subtitle'>
  >({} as Pick<DefaultFields, 'title' | 'subtitle'>)
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { locale } = useLocale()

  const getSolutionsText = async () => {
    try {
      const {
        data: { data },
      } = await fetchSolutionsText(locale)

      setSolutionsText(data)
    } catch (error) {
      console.error('Erro ao buscar dados de textos soluções:', error)
    }
  }

  const getSolutions = async () => {
    try {
      const {
        data: { data },
      } = await fetchSolutions(locale)

      setSolutions(data)
    } catch (error) {
      console.error('Erro ao buscar dados de soluções:', error)
    }
  }

  useEffect(() => {
    getSolutionsText()
    getSolutions()
  }, [locale])

  return (
    <SolutionsWrapper lightBackground={lightBackground}>
      <h2>{solutionsText?.title}</h2>
      <p>{solutionsText?.subtitle}</p>
      <SolutionsGrid isGrid={isGrid}>
        {solutions.length > 0 ? (
          solutions.map((solution) => (
            <SolutionCard
              key={solution.id}
              style={{
                backgroundImage: `url(${imageUrl(solution?.image?.url)})`,
              }}
            >
              <SolutionTitle>{solution?.title}</SolutionTitle>
              <Overlay>
                <CardContent>
                  <IconWrapper>
                    <PiTranslate size={50} />
                  </IconWrapper>
                  <Description>{solution?.description}</Description>
                </CardContent>
                <ActionBar>
                  <ActionButton onClick={() => setIsModalOpen(true)}>
                    {solution?.buttonLabel} <GoArrowUpRight />
                  </ActionButton>
                  <ActionLink to={`/solucoes/${solution.type ?? ''}`}>
                    <AiOutlinePlus color='#0024BE' />
                  </ActionLink>
                </ActionBar>
              </Overlay>
              <Modal
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
                serviceType={{ value: solution.title, label: solution.title }}
              />
            </SolutionCard>
          ))
        ) : (
          <p>Nenhuma solução encontrada.</p>
        )}
      </SolutionsGrid>
    </SolutionsWrapper>
  )
}

export default SolutionsBox

// Styled Components
const SolutionsWrapper = styled.div<{ lightBackground?: boolean }>`
  padding: 100px 30px;
  text-align: center;
  background-color: ${({ lightBackground }) =>
    lightBackground ? '#ECECEC' : 'var(--soft-blue)'};
  color: var(--grey-black);

  h2 {
    font-size: 28px;
    color: ${({ lightBackground }) =>
    lightBackground ? 'var(--gray-black)' : 'white'};
    text-align: left;
    font-weight: 700;
    margin-bottom: 5px;

    @media (max-width: 768px) {
      font-size: 24px;
    }
  }

  p {
    font-size: 18px;
    text-align: left;
    color: ${({ lightBackground }) =>
    lightBackground ? 'var(--gray-black)' : 'white'};
    margin-bottom: 40px;
  }

  @media (max-width: 768px) {
    padding: 40px 20px;
  }
`

const SolutionsGrid = styled.div<{ isGrid?: boolean }>`
  display: ${({ isGrid }) => (isGrid ? 'grid' : 'flex')};
  grid-template-columns: ${({ isGrid }) => (isGrid ? 'repeat(3, 1fr)' : 'none')};
  grid-template-rows: ${({ isGrid }) => (isGrid ? 'auto' : 'none')};
  gap: 20px;
  overflow: ${({ isGrid }) => (isGrid ? 'visible' : 'scroll')};

  &::-webkit-scrollbar {
    width: 0px;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: var(--hard-blue);
    border-radius: 10px;
    opacity: 1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #02277e;
  }

  @media (max-width: 768px) {
    grid-template-columns: ${({ isGrid }) => (isGrid ? '1fr' : 'none')};
    grid-template-rows: ${({ isGrid }) => (isGrid ? 'repeat(9, auto)' : 'none')};
  }
`

const SolutionCard = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 0px;
  min-height: 450px;
  min-width: 370px;
  cursor: pointer;
  transition: all 0.3s ease;
  background: var(--white-transparent);
  background-size: cover;
  background-position: center;

  &:hover > div {
    opacity: 1;
  }

  &:hover h3 {
    bottom: 90px;
  }
`

const SolutionTitle = styled.h3`
  position: absolute;
  bottom: 20px;
  left: 20px;
  color: white;
  padding: 10px;
  border-radius: 5px;

  opacity: 1;
  z-index: 2;
  transition: bottom 0.3s ease;
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  opacity: 0;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;
`

const CardContent = styled.div`
  padding: 20px;
  text-align: center;
  align-items: start;
  display: flex;
  flex-direction: column;
`

const IconWrapper = styled.div`
  margin-bottom: 20px;
  color: white;
`

const Description = styled.p`
  font-size: 16px;
  margin-bottom: 20px;
  color: white;
`

const ActionBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const ActionLink = styled(Link)`
  min-height: 70px;
  color: white;
  background-color: white;
  font-size: 16px;
  text-decoration: none;
  width: fit-content;
  padding: 0 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1em;
`

const ActionButton = styled.a`
  color: white;
  font-size: 16px;
  text-decoration: none;
  transition: 0.3s;
  display: flex;
  padding: 0 25px;
  min-height: 70px;
  align-items: center;
  justify-content: space-between;
  background: var(--hard-blue);
  gap: 1em;
  width: 100%;
  transition: all 0.3s ease;

  &:hover {
    background-color: #ffffff90;
  }
`