import axios, { AxiosResponse } from 'axios';
import { ReactNode } from 'react';
export interface Industry {
  [x: string]: any;
  icon: ReactNode;
  id: number;
  attributes: {
    title: string;
    description: string;
    iconName: string | null;
  };
}

export interface Solution {
  id: number;
  documentId: string;
  title: string;
  description: string;
  how: string;
  buttonLabel: string;
  type: string;
  image?: {
    id: number;
    documentId: string;
    name: string;
    url: string;
    formats: {
      thumbnail: {
        url: string;
      };
    };
  };
  createdAt: string;
  updatedAt: string;
  publishedAt: string;
  locale: string;
}

export interface Testimonial {
  id: number;
  attributes: {
    name: string;
    text: string;
  };
}

export interface Translator {
  email: string;
  nome: string;
  telefone: string;
  cidadeUF: string;
  idiomas: string;
  especializacao: string;
  experienciaAnos: string;
  ferramentasTraducao: string;
  certificacoes: string;
  comentarios?: string;
  cartaApresentacao: File | null;
  curriculo: File | null;
  trabalhosAnteriores: File | null;
  referencias: File | null;
}

export interface RequestQuote {
  email: string;
  nome: string;
  telefone: string;
  projetoTipo: string;
  origem: string;
  destino: string;
  detalhesProjeto: string;
  dataEntrega: string;
  arquivo: File | null;
}

export interface DefaultFields {
  id: number;
  title: string;
  subtitle: string;
  iconName: string;
  buttonLabelBeTranslator: string;
  buttonLabelRequestQuote: string;
}

export interface ClientsLogosCarousel extends DefaultFields {
  list: Image[];
}

export interface MainHomeBanner {
  id: number;
  documentId: string;
  title: string;
  subtitle: string;
  badge: string;
  buttonLabelBeTranslator: string;
  buttonLabelRequestQuote: string;
  createdAt: Date;
  updatedAt: Date;
  publishedAt: Date;
  image: Image;
}

export interface Image {
  id: number;
  name: string;
  alternativeText: null;
  width: number;
  height: number;
  mime: string;
  size: number;
  url: string;
  previewUrl: null;
}

export interface IndustrySectionTexts {
  title_part_1: string;
  title_part_2: string;
  title_part_3: string;
  subtitle: string;
  buttonLabelBeTranslator: string;
  buttonLabelRequestQuote: string;
}

export interface CultureSectionTexts extends DefaultFields {}

export interface HowItWorksSectionTexts extends DefaultFields {
  list: DefaultFields[];
}

export interface FaqTexts {
  question: string;
  answer: string;
}
export interface FaqSectionTexts extends DefaultFields {
  faq: FaqTexts[];
}

export interface FooterSectionTexts extends DefaultFields {
  policyText: string;
  rightsReserved: string;
  list: {
    title: string;
    footerLinks: {
      label: string;
      href: string;
    }[];
  }[];
}

export interface HeaderSectionTexts extends DefaultFields {
  list: {
    title: string;
    href: string;
  }[];
}

export interface MainTranslatorBanner extends DefaultFields {
  badge: string;
  image: Image;
}

export interface WhyTranslatorTexts extends DefaultFields {
  list: DefaultFields[];
}

export interface RegisterTranslatorTexts extends DefaultFields {
  badge: string;
  nameLabel: string;
  namePlaceholder: string;
  emailLabel: string;
  emailPlaceholder: string;
  phoneLabel: string;
  phonePlaceholder: string;
  cityUFLabel: string;
  cityUFPlaceholder: string;
  certificateLabel: string;
  certificatePlaceholder: string;
  languageLabel: string;
  languagePlaceholder: string;
  specialtyLabel: string;
  specialtyPlaceholder: string;
  experienceLabel: string;
  experiencePlaceholder: string;
  toolsTranslatorLabel: string;
  toolsTranslatorPlaceholder: string;
  coverLetterLabel: string;
  cvLabel: string;
  previousWorkLabel: string;
  referenciesLabel: string;
  commentLabel: string;
  commentPlaceholder: string;
  buttonLabelSubmit: string;
  solutions: {
    value: string;
    label: string;
  }[];
}

export interface TestimonialTranslatorTexts extends DefaultFields {
  list: {
    description: string;
    name: string;
    image: Image;
  }[];
}

export interface ValuesText {
  sections: {
    about: DefaultFields;
    moreAbout: {
      description: string;
    } & DefaultFields;
    values: {
      title: string;
      list: {
        description: string;
        iconName: string;
      }[];
    };
  };
}

export interface RegisterTranslatorModalForm {
  data: {
    title: string;
    nextButtonLabel: string;
    step1: Step1;
    step2: Step2;
    step3: Step3;
  };
}

export interface Step1 {
  nameLabel: string;
  namePlaceholder: string;
  emailLabel: string;
  emailPlaceholder: string;
  telefoneLabel: string;
  telefonePlaceholder: string;
  projectTypeLabel: string;
  projectTypePlaceholder: string;
  solutions: {
    value: string;
    label: string;
  }[];
}

export interface Step2 {
  originLanguageLabel: string;
  originLanguagePlaceholder: string;
  destinyLanguageLabel: string;
  destinyLanguagePlaceholder: string;
  detailsLabel: string;
  detailsPlaceholder: string;
  dateLabel: string;
  datePlaceholder: string;
  fileLabel: string;
  filePlaceholder: string;
}

export interface Step3 {
  successMessage: string;
}

interface StrapiResponse<T> {
  data: T;
  meta: {
    page: number;
    pageCount: number;
    pageSize: number;
    total: number;
  } | null;
}

const url = 'https://engaging-chickens-0267a7699e.strapiapp.com/api';
//const url = 'http://localhost:1337/api'

// Instância do Axios configurada
const api = axios.create({
  baseURL: url,
  headers: {
    'Content-Type': 'application/json',
    Authorization:
      'Bearer 50917dfedac99d294c19e5c27b605c3cf3adf567672cd81b448db972fbf3d529c8e21b3c17a5411f24e773d7cbb8e1ccee8935562ca239fd1bc08c7ca929f3ffdd17a82f6b869e2fe82c609d24658b38286544bc09bb8522a4f2818e461fefde6310413a32dd81500f1501381da26aca834ad07c5a911e4856529190e2434683',
  },
});

export const imageUrl = (image?: string) =>
  image
    ? `https://engaging-chickens-0267a7699e.media.strapiapp.com/${image}`
    : '/path/to/default-image.png';

// Função para buscar as soluções com `populate` para carregar todas as relações, incluindo imagens
export const fetchSolutions = async (locale: string) =>
  api.get<StrapiResponse<Solution[]>>(`/solutions?populate=*&locale=${locale}`);

export const fetchSolutionsText = async (locale: string) =>
  api.get<StrapiResponse<DefaultFields>>(
    `/solution-text?populate=*&locale=${locale}`,
  );

// Função para buscar as indústrias com `populate` para carregar todas as relações, se houverem
export const fetchIndustries = async (locale: string) =>
  api.get<StrapiResponse<Industry[]>>(
    `/industries?populate=*&locale=${locale}`,
  );

export const fetchClientsCarousel = async (locale: string) =>
  api.get<StrapiResponse<ClientsLogosCarousel>>(
    `/clients-carousel?populate=*&locale=${locale}`,
  );

export const fetchHomeBannerTexts = async (locale: string) =>
  api.get<StrapiResponse<MainHomeBanner>>(
    `/main-home-banner?populate=*&locale=${locale}`,
  );

export const fetchIndustrySectionTexts = async (locale: string) =>
  api.get<StrapiResponse<IndustrySectionTexts>>(
    `/industry-section-text?populate=*&locale=${locale}`,
  );

export const fetchCultureSectionTexts = async (locale: string) =>
  api.get<StrapiResponse<CultureSectionTexts[]>>(
    `/culture-sections?populate=*&locale=${locale}`,
  );

export const fetchHowItWorksSectionTexts = async (locale: string) =>
  api.get<StrapiResponse<HowItWorksSectionTexts>>(
    `/how-it-work-text?populate=*&locale=${locale}`,
  );

export const fetchFaq = async (locale: string) =>
  api.get<StrapiResponse<FaqSectionTexts>>(`/faq?populate=*&locale=${locale}`);

export const fetchFooter = async (locale: string) =>
  api.get<StrapiResponse<FooterSectionTexts>>(
    `/footer-text?populate=*&locale=${locale}`,
  );

export const fetchHeaderTexts = async (locale: string) =>
  api.get<StrapiResponse<HeaderSectionTexts>>(
    `/header-text?populate=*&locale=${locale}`,
  );

export const fetchTranslatorBannerTexts = async (locale: string) =>
  api.get<StrapiResponse<MainTranslatorBanner>>(
    `/main-translator-banner?populate=*&locale=${locale}`,
  );

export const fetchWhyTranslatorText = async (locale: string) =>
  api.get<StrapiResponse<WhyTranslatorTexts>>(
    `/why-work-here?populate=*&locale=${locale}`,
  );

export const fetchRegisterTranslatorTexts = async (locale: string) =>
  api.get<StrapiResponse<RegisterTranslatorTexts>>(
    `/register-translator?populate=*&locale=${locale}`,
  );

export const fetchTestimonialTranslatorTexts = async (locale: string) =>
  api.get<StrapiResponse<TestimonialTranslatorTexts>>(
    `/testimonial-translator-text?populate=*&locale=${locale}`,
  );

export const fetchValuesText = async (locale: string) =>
  api.get<StrapiResponse<ValuesText>>(
    `/values-text?populate=*&locale=${locale}`,
  );

export const fetchRegisterTranslatorModalForm = async (locale: string) =>
  api.get<StrapiResponse<RegisterTranslatorModalForm>>(
    `/register-translator-modal-form?populate=*&locale=${locale}`,
  );

// Função para buscar os depoimentos
export const getTestimonials = async (): Promise<
  StrapiResponse<Testimonial[]>
> => {
  const response: AxiosResponse<StrapiResponse<Testimonial[]>> = await api.get(
    '/testimonials?populate=*',
  );
  return response.data;
};

// Função para cadastrar tradutores
export const postTranslator = async (data: Translator) => {
  let formData = new FormData();

  const formattedData = {
    certificacoes: data.certificacoes,
    cidadeUF: data.cidadeUF,
    comentarios: data.comentarios,
    email: data.email,
    especializacao: data.especializacao,
    experienciaAnos: data.experienciaAnos,
    ferramentasTraducao: data.ferramentasTraducao,
    idiomas: data.idiomas,
    nome: data.nome,
    telefone: data.telefone,
  };

  formData.append('data', JSON.stringify(formattedData));

  formData.append('files.cartaApresentacao', data.cartaApresentacao!);

  formData.append('files.curriculo', data.curriculo!);

  formData.append('files.trabalhosAnteriores', data.trabalhosAnteriores!);

  if (data?.referencias) formData.append('files.referencias', data.referencias);

  return api.post<StrapiResponse<Translator>>('/translators', formData, {
    headers: {
      Authorization:
        'Bearer 50917dfedac99d294c19e5c27b605c3cf3adf567672cd81b448db972fbf3d529c8e21b3c17a5411f24e773d7cbb8e1ccee8935562ca239fd1bc08c7ca929f3ffdd17a82f6b869e2fe82c609d24658b38286544bc09bb8522a4f2818e461fefde6310413a32dd81500f1501381da26aca834ad07c5a911e4856529190e2434683',
      'Content-Type': 'multipart/form-data',
    },
  });
};

// Função para cadastrar orçamentos
export const postRequestQuote = async (data: RequestQuote) => {
  let formData = new FormData();

  const formattedData = {
    dataEntrega: data.dataEntrega,
    destino: data.destino,
    detalhesProjeto: data.detalhesProjeto,
    email: data.email,
    nome: data.nome,
    origem: data.origem,
    projetoTipo: data.projetoTipo,
    telefone: data.telefone,
  };

  formData.append('data', JSON.stringify(formattedData));

  formData.append('files.single', data.arquivo!);

  return api.post<StrapiResponse<RequestQuote>>('/orcamentos', formData, {
    headers: {
      Authorization:
        'Bearer 50917dfedac99d294c19e5c27b605c3cf3adf567672cd81b448db972fbf3d529c8e21b3c17a5411f24e773d7cbb8e1ccee8935562ca239fd1bc08c7ca929f3ffdd17a82f6b869e2fe82c609d24658b38286544bc09bb8522a4f2818e461fefde6310413a32dd81500f1501381da26aca834ad07c5a911e4856529190e2434683',
      'Content-Type': 'multipart/form-data',
    },
  });
};

export default api;
